import React, { useContext } from "react"
import { Menu, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { authService } from "../services/auth.service"
import { Link, navigate } from "gatsby"
import { AuthContext } from "../context/auth"

export default function NavMenu() {
  const { setUser } = useContext(AuthContext)
  let user = authService.getUser()
  // console.log(user)
  let isLoggedIn = authService.isLoggedIn()
  const handleLogout = async () => {
    setUser({}) //clean user in context
    authService.logout()
    navigate("/")
  }

  return (
    <div className=" text-right  z-40 ">
      {!isLoggedIn ? (
        <Link className="text-white font-bold" to="/login">
          {/* <Menu as="div" className="relative inline-block text-left"> */}
          {/* <Menu.Button */}
          <button
            className="inline-flex
            justify-center
            w-full
            px-4
            py-2
            text-sm
            font-large
            text-white
            bg-white
            rounded-md
            hover:bg-red-600
            bg-opacity-10
            hover:bg-opacity-800
            focus:outline-none 
            "
          >
            Login
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
              />
            </svg>
          </button>
          {/* </Menu.Button> */}
          {/* </Menu> */}
        </Link>
      ) : (
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-bold text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              {user.name}
              <ChevronDownIcon
                className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? " text-red-500 font-semibold" : "text-gray-900"
                      } group flex font-semibold rounded-md items-center w-full px-2 py-2 text-sm`}
                      onClick={() => {
                        navigate("/app/account")
                      }}
                    >
                      {active ? (
                        <AccountIcon
                          className="w-5 h-5 mr-2"
                          aria-hidden="true"
                        />
                      ) : (
                        <AccountIcon
                          className="w-5 h-5 mr-2"
                          aria-hidden="true"
                        />
                      )}
                      Account
                    </button>
                  )}
                </Menu.Item>

                {user.syfirRole === "cpr" ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? "text-red-500 font-semibold"
                            : "text-gray-900"
                        } group flex font-semibold rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={() => {
                          navigate("/app/instrument")
                        }}
                      >
                        {active ? (
                          <InsIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <InsIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        My Instruments
                      </button>
                    )}
                  </Menu.Item>
                ) : null}
              </div>

              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "text-red-500 font-semibold" : "text-gray-900"
                      } group flex font-semibold rounded-md items-center w-full px-2 py-2 text-sm`}
                      onClick={handleLogout}
                    >
                      {active ? (
                        <LogOutIcon
                          className="w-5 h-5 mr-2 "
                          aria-hidden="true"
                        />
                      ) : (
                        <LogOutIcon
                          className="w-5 h-5 mr-2 "
                          aria-hidden="true"
                        />
                      )}
                      Logout
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  )
}

function InsIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z"
        fill="#EE8181"
        stroke="#E43A39"
      />
    </svg>
  )
}

function AccountIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
        clipRule="evenodd"
        fill="#EE8181"
        stroke="#E43A39"
      />
    </svg>
  )
}

function LogOutIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
        fill="#EE8181"
        stroke="#E43A39"
      />
    </svg>
  )
}
